/**
 * 웹사이트 웰컴 메시지.
 */
function welcome() {
  console.info(
    `=== 세컨솔드 Web ===
- Version: ${_APP_VERSION}${_APP_GIT_SHA ? `+${_APP_GIT_SHA}` : ''}
- Build Date: ${new Date(_BUILD_TIME_MS).toLocaleString()}`,
  );
}

export default welcome;
